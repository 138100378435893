import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MarketingLayout from "$components/MarketingLayout";
import MailchimpSignup from "$components/MailchimpSignup";
import Hero from "$components/Hero";
import { Flex, Col } from "$components/Flex";
import MarketingSection from "$components/MarketingSection";
import MarketingDivide from "$components/MarketingDivide";
import MarketingCodebox from "$components/MarketingCodebox";
import MarketingBullets from "$components/MarketingBullets";
import MarketingProduct from "$components/MarketingProduct";
import postgresPostgraphileGraphql from "$images/postgres_postgraphile_graphql.png";
import Testimonial from "$components/MarketingTestimonial";
import GitHubButton from "react-github-btn";
import COLOURS from "$components/colours";
export const _frontmatter = {
  "layout": "marketing",
  "path": "/",
  "title": "PostGraphile - full GraphQL API server in an instant from PostgreSQL database"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <MarketingLayout blue mdxType="MarketingLayout">
      <MarketingSection bg="dark" nopad mdxType="MarketingSection">
        <div style={{
          height: '60px'
        }} />
      </MarketingSection>
      <MarketingSection bg="white" mdxType="MarketingSection">
        <Flex mdxType="Flex">
          <Col span="6" mdxType="Col">
            <MarketingProduct big name={<span>Introducing Gra<em>fast</em>!</span>} headline="The next-generation planning and execution engine for GraphQL" mdxType="MarketingProduct">
              <p>{`Enables greater performance and efficiency via deeper integration into your existing Node.js or remote business logic, no matter the shape of your GraphQL schema.`}</p>
              <p><strong parentName="p"><a parentName="strong" {...{
                    "href": "https://grafast.org"
                  }}>{`Gra`}<em>{`fast`}</em></a>{` and `}<a parentName="strong" {...{
                    "href": "https://postgraphile.org"
                  }}>{`PostGraphile V5`}</a>{` are in beta, funded by the Graphile community.`}</strong></p>
              <div className='df flex-row justify-center' style={{
                height: '3rem'
              }}>
                <div className='ph2 df flex-column justify-center'>
                  <a className='button--outline' href='/sponsor/'><span className='fas fa-heart' /> Support Grafast</a>{' '}
                </div>
              </div>
            </MarketingProduct>
          </Col>
          <Col span="6" mdxType="Col">
            <p>&nbsp;</p>
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/x0FMjL5-kNI" title="YouTube video player" frameBorder="1" style={{
              "border": "6px solid #1b1b3d",
              "borderRadius": "10px"
            }} allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
          </Col>
        </Flex>
      </MarketingSection>
      <MarketingDivide from="white" to="medium" via="dark" mdxType="MarketingDivide" />
      <MarketingSection bg="medium" mdxType="MarketingSection">
        <h2>{`Crowd-funded open-source software`}</h2>
        <div style={{
          maxWidth: '48rem',
          margin: '0 auto'
        }}>
          <p>{`We're extremely grateful to our sponsors, for helping to fund ongoing
development on PostGraphile, Graphile Engine, Graphile Worker and Graphile
Migrate.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`THANK YOU!`}</strong></p>
        </div>
        <a className='button--solid' href='/sponsor/'><span className='fas fa-arrow-right' /> Learn more about sponsors and sponsorship</a>
      </MarketingSection>
      <MarketingDivide from="medium" to="white" via="light" mdxType="MarketingDivide" />
      <MarketingSection bg="white" mdxType="MarketingSection">
        <Flex mdxType="Flex">
  <Col className="center" span="8" mdxType="Col">
    <img src={postgresPostgraphileGraphql} style={{
              maxWidth: "80%"
            }} alt="PostGraphile" />
  </Col>
        </Flex>
        <h3>{`Extensible high-performance automatic GraphQL API for PostgreSQL`}</h3>
        <div className='df flex-row justify-center' style={{
          height: '3rem'
        }}>
  <div className='ph2 df flex-column justify-center'>
  <a className='button--outline' href='/postgraphile/introduction/'><span className='fas fa-book-open' /> V4 Documentation</a>{' '}
  </div>
  <div className='ph2 df flex-column justify-center'>
  <a className='button--solid' href='https://postgraphile.org'>PostGraphile.org (V5)</a>{' '}
  </div>
  <div className='ph2 df h-100 flex-column justify-center'>
  <GitHubButton href="https://github.com/graphile/postgraphile" data-size="large" data-show-count="true" aria-label="Star graphile/postgraphile on GitHub" mdxType="GitHubButton">
    Star
  </GitHubButton>
  </div>
        </div>
      </MarketingSection>
      <MarketingDivide from="white" to="dark" via="light" down mdxType="MarketingDivide" />
      <MarketingSection bg="dark" mdxType="MarketingSection">
        <Flex mdxType="Flex">
          <Col span="6" mdxType="Col">
            <img src="/images/graphile-starter-logo-light.svg" alt="Graphile Heart" className='mw5 pt4' />
          </Col>
          <Col span="6" mdxType="Col">
            <MarketingProduct big name="Graphile Starter" headline="A quick-start project for full-stack application development in React, Node.js, GraphQL and PostgreSQL" more="https://github.com/graphile/starter" mdxType="MarketingProduct">
              <p>{`Graphile Starter includes the foundations of a modern web application, with a full user registration system, session management, optimised job queue, pre-configured tooling, tests and much more.`}</p>
            </MarketingProduct>
          </Col>
        </Flex>
      </MarketingSection>
      <MarketingDivide from="dark" to="medium" via="light" down mdxType="MarketingDivide" />
      <MarketingSection bg="medium" mdxType="MarketingSection">
        <h2>{`Database tools`}</h2>
        <Flex mdxType="Flex">
          <Col span="6" mdxType="Col">
            <MarketingProduct name="Graphile Worker" headline="High performance Node.js/PostgreSQL job queue" docs="https://worker.graphile.org" github="https://github.com/graphile/worker" mdxType="MarketingProduct">
Run jobs (e.g. sending emails, generating PDFs, …) "in the background" so
that your HTTP response code is not held up. Starts jobs almost instantly (2ms latency).
              <p>{`Used with any PostgreSQL-backed application.`}</p>
            </MarketingProduct>
          </Col>
          <Col span="6" mdxType="Col">
            <MarketingProduct name="Graphile Migrate" headline="Opinionated SQL-powered productive roll-forward migration tool for PostgreSQL." docs="https://github.com/graphile/migrate" github="https://github.com/graphile/migrate" mdxType="MarketingProduct">
              <p>{`Experimental, being developed in the open. Focusses on fast iteration speed.`}</p>
            </MarketingProduct>
          </Col>
        </Flex>
      </MarketingSection>
      <MarketingDivide from="medium" to="nodes" via="dark" mdxType="MarketingDivide" />
      <MarketingSection bg="nodes" mdxType="MarketingSection">
        <MarketingProduct big name="Development Support" headline="Priority text support straight from the maintainer" more="/support/" mdxType="MarketingProduct">
Give your company access to the knowledge and experience of the Graphile team through your chat server and GitHub/GitLab organisation. Reference your code verbatim and arrange calls for any trickier topics.
        </MarketingProduct>
      </MarketingSection>
      <MarketingDivide from="nodes" to="dark" mdxType="MarketingDivide" />
      <MarketingSection bg="dark" mdxType="MarketingSection">
        <Flex mdxType="Flex">
          <Col span="6" mdxType="Col">
            <img src="/images/graphile.optimized.svg" alt="Graphile Heart" className='mw5 pt4' />
          </Col>
          <Col span="6" mdxType="Col">
            <MarketingProduct big name="Graphile Build" headline="Build high-performance easily-extensible GraphQL schemas by combining plugins" docs="/graphile-build/getting-started/" more="/graphile-build/" mdxType="MarketingProduct">
              <p>{`Graphile Build is the database-independent heart of PostGraphile — it's an
extremely powerful way to build extensible automatic GraphQL APIs over any
data source.`}</p>
            </MarketingProduct>
          </Col>
        </Flex>
      </MarketingSection>
      <MarketingDivide from="dark" to="white" via="light" down mdxType="MarketingDivide" />
    </MarketingLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      